<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>
<script>
export default {}
</script>
<style lang="scss">
.vc-container {
  font-family: 'Poppins';
}
</style>
